<template>
  <div class="boost-container">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item><i class="icon-home"></i>促进服务管理</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="search-box">
        <Row>
          <Col span="24">
            <h2 class="search-title border-bottom">按条件搜索</h2>
          </Col>
        </Row>
        <Form class="search-form" :label-width="85">
          <Row>
            <Col span="5">
              <Form-item label="建档日期：">
                <Date-picker @on-change="changeTime" type="daterange" placeholder="选择日期"></Date-picker>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="会员姓名：">
                <Input v-model="searchForm.memble_name" placeholder="请输入..."></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="促进师：">
                <Cascader :data="booster" v-model="boosterArr"></Cascader>
              </Form-item>
            </Col>
             <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="促进师分配状态：">
                <Select clearable v-model="searchForm.booster_status" placeholder="请选择" @on-change="booster_status">
                  <Option :value="1">未分配</Option>
                  <Option :value="2">已分配</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
          </Row>
          <Row>
            <Col span="5">
              <Form-item label="省份：">
                <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
                <Select clearable v-model="searchForm.pid" placeholder="请选择" @on-change="changePro" v-else>
                  <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="城市：">
                <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
                <Select clearable v-model="searchForm.cid" placeholder="请选择" @on-change="changeCity" v-else>
                  <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="门店：">
                <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
                <Select clearable v-model="searchForm.sid" placeholder="请选择" v-else>
                  <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="促进状态：">
                <Select clearable v-model="searchForm.boost_status" placeholder="请选择" @on-change="boost_status">
                  <Option :value="1">未促进</Option>
                  <Option :value="2">已促进</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>
          <div class="tac">
            <Button type="primary" class="search-btn" @click="handleSubmit()">搜索</Button>
          </div>
        </Form>
      </div>
      <div class="filter-list-tpl">
        <Row>
          <Col span="24"> 请选择：
            <Radio-group v-model="searchForm.status" @on-change="changeStatus">
              <Radio label="0">全部</Radio>
              <Radio label="1">未分配</Radio>
              <Radio label="2">待定制</Radio>
              <Radio label="3">待审核</Radio>
              <Radio label="4">已审核</Radio>
              <Radio label="5">待调整</Radio>
              <Radio label="6">已调整</Radio>
              <Radio label="7">调整件</Radio>
            </Radio-group>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <a href="javascript:;" class="btn-allot" @click="distributeAll()" v-if="this.power.indexOf('促进服务管理--分配促进名单权限') > -1">全部分配促进师</a>
            <a href="javascript:;" class="btn-allot" @click="distributeDocAll()" v-if="this.power.indexOf('医生--分配促进名单权限') > -1">全部分配医生</a>
            <a href="javascript:;" class="btn-allot" @click="distribute()" v-if="this.power.indexOf('促进服务管理--分配促进名单权限') > -1">分配促进师</a>
            <a href="javascript:;" class="btn-allot" @click="distributeDoc()" v-if="this.power.indexOf('医生--分配促进名单权限') > -1">分配医生</a>
          </Col>
        </Row>
        <Table stripe :columns="columns" :data="boostList" @on-selection-change="selectOne"></Table>
        <Row>
          <Col span="12">
          <div class="table-oprate">
            <!--<Button @click="delBoost" v-if="this.power.indexOf('促进服务管理--删除促进记录的权限') > -1">删除</Button>-->
          </div>
          </Col>
          <Col span="12">
          <span class="records">共{{pageTotal}}条记录</span>
          </Col>
        </Row>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
          </div>
        </div>
      </div>
    </div>
    <!--分配促进师modal-->
    <Modal v-model="fpmodal" title="分配促进师">
      <div class="slotbody">
        <p>选择促进小组</p>
        <Select v-model="distribution.team_id" placeholder="请选择" @on-change="changeTeam">
          <Option :value="v.id" v-for="v in teamList" :key="v.id">{{v.name}}</Option>
        </Select>
        <p>选择促进师</p>
        <Select v-model="distribution.user_id" placeholder="请选择">
          <Option :value="v.id" v-for="v in teamMemberList" :key="v.id">{{v.name}}</Option>
        </Select>
      </div>
      <div slot="footer" class="tac">
        <Button @click="okDistri">确定</Button>
        <Button @click="cancel">取消</Button>
      </div>
    </Modal>
    <!--分配医生modal-->
    <Modal v-model="docmodal" title="分配医生">
      <div class="slotbody">
        <p>选择医生小组</p>
        <Select v-model="distriDoc.team_id" placeholder="请选择" @on-change="changeTeamDoc">
          <Option :value="v.id" v-for="v in docTeamList" :key="v.id">{{v.name}}</Option>
        </Select>
        <p>选择医生</p>
        <Select v-model="distriDoc.user_id" placeholder="请选择">
          <Option :value="v.id" v-for="v in docMemberList" :key="v.id">{{v.name}}</Option>
        </Select>
      </div>
      <div slot="footer" class="tac">
        <Button @click="okDoc">确定</Button>
        <Button @click="cancelDoc">取消</Button>
      </div>
    </Modal>
    <!--全部分配促进师modal-->
    <Modal v-model="fpmodalAll" title="分配促进师">
      <div class="slotbody">
        <p>选择促进小组</p>
        <Select v-model="distribution.team_id" placeholder="请选择" @on-change="changeTeam">
          <Option :value="v.id" v-for="v in teamList" :key="v.id">{{v.name}}</Option>
        </Select>
        <p>选择促进师</p>
        <Select v-model="distribution.user_id" placeholder="请选择">
          <Option :value="v.id" v-for="v in teamMemberList" :key="v.id">{{v.name}}</Option>
        </Select>
      </div>
      <div slot="footer" class="tac">
        <Button @click="okDistriAll">确定</Button>
        <Button @click="cancelAll">取消</Button>
      </div>
    </Modal>
    <!--全部分配医生modal-->
    <Modal v-model="docmodalAll" title="分配医生">
      <div class="slotbody">
        <p>选择医生小组</p>
        <Select v-model="distriDoc.team_id" placeholder="请选择" @on-change="changeTeamDoc">
          <Option :value="v.id" v-for="v in docTeamList" :key="v.id">{{v.name}}</Option>
        </Select>
        <p>选择医生</p>
        <Select v-model="distriDoc.user_id" placeholder="请选择">
          <Option :value="v.id" v-for="v in docMemberList" :key="v.id">{{v.name}}</Option>
        </Select>
      </div>
      <div slot="footer" class="tac">
        <Button @click="okDocAll">确定</Button>
        <Button @click="cancelDocAll">取消</Button>
      </div>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import boostService from '@/services/boostService';
import doctorService from '@/services/doctorService';
export default {
	components: {
		'v-title': vTitle,
	},
	data() {
		return {
			searchForm: {
				page: 1,
				size: 10,
				start_time: '',
				end_time: '',
				memble_name: '',
				pid: '',
				cid: '',
				sid: '',
				booster_id: '',
				status: 0,
				booster_status: 0,
				boost_status: 0,
			},
			distributeAllForm: {
				start_time: '',
				end_time: '',
				team_id: '',
				user_id: '',
				memble_name: '',
				pid: '',
				cid: '',
				sid: '',
				booster_id: '',
				status: '',
			},
			distributeDocAllForm: {
				start_time: '',
				end_time: '',
				team_id: '',
				user_id: '',
				memble_name: '',
				pid: '',
				cid: '',
				sid: '',
				booster_id: '',
				status: '',
			},
			// del: {
			//   boost_ids: ''
			// },
			distribution: {
				member_ids: '',
				team_id: '',
				user_id: '',
			},
			distriDoc: {
				member_ids: '',
				team_id: '',
				user_id: '',
			},
			belong_booster_ids: '',
			booster: [],
			boosterArr: [],
			teamList: [],
			teamMemberList: [],
			team_member: {},
			docTeamList: [],
			docMemberList: [],
			doc_member: {},
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '上次促进日期', key: 'last_time', align: 'center' },
				{
					title: '下次促进日期',
					key: 'next_time',
					align: 'center',
					render: (h, params) => {
						if (new Date(params.row.next_time).getTime() <= Date.now()) {
							return h(
								'div',
								{
									style: {
										color: '#f00',
									},
								},
								params.row.next_time,
							);
						} else {
							return h('div', params.row.next_time);
						}
					},
				},
				{
					title: '会员姓名',
					key: 'member_name',
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									style: {
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href:
											'#/base/member/detail/' +
											this.boostList[params.index].member_id +
											'/' +
											2 +
											'/' +
											1,
									},
								},
								params.row.member_name,
							),
						]);
					},
				},
				{ title: '顾客类型', key: 'new_member_type', align: 'center' },
				{ title: '所属分院', key: 'branch_name', align: 'center' },
				{ title: '联系电话', key: 'mobile', align: 'center' },
				{ title: '方案名称', key: 'plan_name', align: 'center' },
				{ title: '方案状态', key: 'status_string', align: 'center' },
				{ title: '促进师', key: 'belong_booster_name', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						if (this.power.indexOf('促进服务管理--添加促进任务的权限') > -1) {
							if (this.boostList[params.index].belong_booster_id) {
								return h('div', [
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											attrs: {
												target: '_blank',
												href: '#/base/boost/list/' + this.boostList[params.index].member_id,
											},
										},
										'列表',
									),
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											attrs: {
												target: '_blank',
												href: '#/base/boost/start/' + this.boostList[params.index].member_id,
											},
										},
										'促进',
									),
								]);
							} else {
								return h('div', [
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											attrs: {
												target: '_blank',
												href: '#/base/boost/list/' + this.boostList[params.index].member_id,
											},
										},
										'列表',
									),
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											on: {
												click: () => {
													this.$Message.warning('请先分配促进师');
												},
											},
										},
										'促进',
									),
								]);
							}
						} else {
							return h('div', [
								h(
									'a',
									{
										style: {
											margin: '0 10px',
											color: '#ff8400',
										},
										attrs: {
											target: '_blank',
											href: '#/base/boost/list/' + this.boostList[params.index].member_id,
										},
									},
									'列表',
								),
								h(
									'a',
									{
										style: {
											margin: '0 10px',
											color: '#ff8400',
										},
										on: {
											click: () => {
												this.$Message.warning('抱歉，你暂没有该权限');
											},
										},
									},
									'促进',
								),
							]);
						}
					},
				},
			],
			boostList: [],
			pageTotal: 0,
			fpmodal: false,
			docmodal: false,
			fpmodalAll: false,
			docmodalAll: false,
			power: '',
			proList: [],
			cityList: [],
			city: {},
			storeList: [],
			store: {},
			pro_name: '',
			city_name: '',
			branch_name: '',
		};
	},
	watch: {
		boosterArr() {
			this.searchForm.booster_id = this.boosterArr[1];
		},
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchForm.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchForm.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchForm.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		this.getBoostList();
		boostService.getBoostSelect().then((data) => {
			this.booster = data.booster;
			this.booster.push({
				label: '未分配',
				value: 'None',
				children: [{ label: '无', value: 'None' }],
			});
			this.teamList = data.team;
			this.team_member = data.team_member;
			this.docTeamList = data.doctor_team;
			this.doc_member = data.doctor_team_member;
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchForm.pid];
			this.searchForm.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchForm.cid];
			this.searchForm.sid = '';
		},
		booster_status(value) {
			this.searchForm.booster_status = value;
		},
		boost_status(value) {
			this.searchForm.boost_status = value;
		},
		getBoostList() {
			boostService.getBoostList(this.searchForm).then((data) => {
				this.boostList = data.list;
				this.pageTotal = data.row_size;
				// this.del.boost_ids = ''
				this.belong_booster_ids = '';
				this.distribution.member_ids = '';
				this.distriDoc.member_ids = '';
			});
		},
		handleSubmit() {
			this.distributeAllForm.start_time = this.searchForm.start_time;
			this.distributeAllForm.end_time = this.searchForm.end_time;
			this.distributeAllForm.memble_name = this.searchForm.memble_name;
			this.distributeAllForm.pid = this.searchForm.pid;
			this.distributeAllForm.cid = this.searchForm.cid;
			this.distributeAllForm.sid = this.searchForm.sid;
			this.distributeAllForm.booster_id = this.searchForm.booster_id;

			this.distributeDocAllForm.start_time = this.searchForm.start_time;
			this.distributeDocAllForm.end_time = this.searchForm.end_time;
			this.distributeDocAllForm.memble_name = this.searchForm.memble_name;
			this.distributeDocAllForm.pid = this.searchForm.pid;
			this.distributeDocAllForm.cid = this.searchForm.cid;
			this.distributeDocAllForm.sid = this.searchForm.sid;
			this.distributeDocAllForm.booster_id = this.searchForm.booster_id;
			this.searchForm.page = 1;
			this.getBoostList();
		},
		changeTime(date) {
			this.searchForm.start_time = date[0];
			this.searchForm.end_time = date[1];
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getBoostList();
		},
		changeStatus() {
			this.distributeAllForm.status = this.searchForm.status;
			this.distributeDocAllForm.status = this.searchForm.status;
			this.searchForm.page = 1;
			this.getBoostList();
		},
		selectOne(selection) {
			let arrBoostertId = [];
			let arrMemberId = [];
			for (var i = 0; i < selection.length; i++) {
				arrBoostertId.push(selection[i].belong_booster_id);
				arrMemberId.push(selection[i].member_id);
			}
			this.belong_booster_ids = arrBoostertId.join(',');
			this.distribution.member_ids = this.distriDoc.member_ids = arrMemberId.join(',');
		},
		// delBoost() {
		//   if (!this.del.boost_ids) {
		//     this.$Message.warning('请先选择要删除的促进服务')
		//   } else {
		//     this.$Modal.confirm({
		//       title: '确认',
		//       content: '确定删除该促进服务吗',
		//       onOk: () => {
		//         this.okdel()
		//       }
		//     })
		//   }
		// },
		// okdel() {
		//   boostService.deleteBoost(this.del).then(() => {
		//     this.getBoostList()
		//   })
		// },
		distribute() {
			// if (this.belong_booster_ids) {
			//   this.$Message.warning('只能对未分配促进师的促进服务分配促进师')
			// } else {
			if (this.distribution.member_ids) {
				this.fpmodal = true;
			} else {
				this.$Message.warning('请先至少选择一个促进服务');
			}
			// }
		},
		distributeDoc() {
			if (this.distriDoc.member_ids) {
				this.docmodal = true;
			} else {
				this.$Message.warning('请先至少选择一个促进服务');
			}
		},
		changeTeam() {
			this.teamMemberList = this.team_member[this.distribution.team_id];
		},
		changeTeamDoc() {
			this.docMemberList = this.doc_member[this.distriDoc.team_id];
		},
		distributeAll() {
			this.fpmodalAll = true;
		},
		distributeDocAll() {
			this.docmodalAll = true;
		},
		okDistri() {
			if (!this.distribution.user_id) {
				this.$Message.warning('请进行具体分配');
			} else {
				boostService.distribute(this.distribution).then(() => {
					this.getBoostList();
					this.fpmodal = false;
				});
			}
		},
		cancel() {
			this.fpmodal = false;
		},
		okDistriAll() {
			this.distributeAllForm.user_id = this.distribution.user_id;
			if (!this.distribution.user_id) {
				this.$Message.warning('请进行具体分配');
			} else {
				boostService.distributeAll(this.distributeAllForm).then(() => {
					this.getBoostList();
					this.fpmodalAll = false;
				});
			}
		},
		cancelAll() {
			this.fpmodalAll = false;
		},
		okDoc() {
			if (!this.distriDoc.user_id) {
				this.$Message.warning('请进行具体分配');
			} else {
				doctorService.distribute(this.distriDoc).then(() => {
					this.getBoostList();
					this.docmodal = false;
				});
			}
		},
		cancelDoc() {
			this.docmodal = false;
		},
		okDocAll() {
			this.distributeDocAllForm.user_id = this.distriDoc.user_id;
			if (!this.distriDoc.user_id) {
				this.$Message.warning('请进行具体分配');
			} else {
				doctorService.distributeAll(this.distributeDocAllForm).then(() => {
					this.getBoostList();
					this.docmodalAll = false;
				});
			}
		},
		cancelDocAll() {
			this.docmodalAll = false;
		},
	},
};
</script>

<style lang="css" scoped>
</style>
